import Api from "@/services/Api";

export default {
  // Gets all Blueprint objects related to a particular group. Takes a refGroup as an argument.
  getGroupBlueprints(refGroup) {
    return Api().get("getGroupBlueprints", {
      params: {
        refGroup: refGroup,
      },
    });
  },

  // Gets the position on a determinate blueprint for a determinate sensor.
  // Takes the deviceId && refBlueprint as parameters.
  getSensorPosition(refBlueprint, groupDeviceIds) {
    return Api().get("getSensorPosition", {
      params: {
        deviceIds: groupDeviceIds,
        refBlueprint: refBlueprint,
      },
    });
  },

  // Saves sensor position and requires the deviceId, x && y coordinates and reference to a blueprint.
  saveSensorPosition(params) {
    return Api().post("saveSensorPosition", params);
  },

  // Saves an uploaded Image and takes a FormData javascript object as the argument.
  uploadFile(fd) {
    return Api().post("uploadFile", fd, {
      onUploadProgress: (uploadEvent) => {
        console.log(
          "Upload Progress: " +
            Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
            "%"
        );
      },
    });
  },

  // Removes a certain group's blueprint from the database and deletes its associated file. Takes a blueprint name, a groupId and a path to the file.
  deleteBlueprint(name, groupId, path) {
    return Api().delete("deleteBlueprint", {
      data: {
        name: name,
        groupId: groupId,
        path: path,
      },
    });
  },
};
