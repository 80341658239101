<template>
  <div
    id="canvasParent"
    class="canvaObj"
    style="display: flex; width: 100%; height: 100%"
  >
    <canvas class="canvaObj mt-4 ml-4 mr-4 mb-4" id="canvas"></canvas>
  </div>
</template>

<script>
import BlueprintService from "@/services/BlueprintService";
export default {
  props: {
    path: {
      required: false,
      type: String,
    },
    blueprintId: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      devices: [],
      sensorObj: [],
      selectedColor: null,
      gridCounter: 0,
    };
  },
  watch: {
    path(val) {
      this.path = val;
      this.refreshDisplay();
    },
    blueprintId(val) {
      this.blueprintId = val;
    },
  },
  async mounted() {
    // Get group devices objects from the store
    this.devices = this.$store.getters.devices;

    this.selectedColor = "purple";

    // Set up the Canvas and Context elements
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");

    let blueprintObj = this.$store.getters.blueprints;

    if (blueprintObj.length != 0) {
      // Draw blueprint and sensors
      await this.init();
    }

    let canvasParent = document.getElementById("canvasParent");
    this.canvas.width = canvasParent.clientWidth;
    this.canvas.height = this.canvas.width * 0.38;
  },

  methods: {
    async init() {
      let self = this;

      this.mountBlueprint(this.path, async () => {
        this.sensorObj = await this.getSensorPositionObj(this.blueprintId);

        this.sensorObj.data.forEach(function (value) {
          value.xCoordinate = parseFloat(
            ((value.xCoordinate / 100) * self.canvas.width).toFixed(3)
          );
          value.yCoordinate = parseFloat(
            ((value.yCoordinate / 100) * self.canvas.height).toFixed(3)
          );
        });

        for (let i = 0; i < this.sensorObj.data.length; i++) {
          await this.drawSensors(
            this.sensorObj.data[i].xCoordinate,
            this.sensorObj.data[i].yCoordinate
          );
        }
      });
    },

    async mountBlueprint(path, cb) {
      this.canvas.style.backgroundImage = "url(blueprints/" + path + ")";
      this.canvas.style.backgroundRepeat = "no-repeat";
      this.canvas.style.backgroundSize = "cover";
      await cb();
    },

    async refreshDisplay() {
      this.clearCanvas();
      await this.mountBlueprint(this.path, async () => {
        let self = this;

        let sensorObj = await this.getSensorPositionObj(this.blueprintId);
        sensorObj.data.forEach(function (value) {
          value.xCoordinate = parseFloat(
            ((value.xCoordinate / 100) * self.canvas.width).toFixed(3)
          );
          value.yCoordinate = parseFloat(
            ((value.yCoordinate / 100) * self.canvas.height).toFixed(3)
          );
        });

        for (let i = 0; i < sensorObj.data.length; i++) {
          await this.drawSensors(
            sensorObj.data[i].xCoordinate,
            sensorObj.data[i].yCoordinate
          );
        }
      });
    },

    async getSensorPositionObj(blueprintId) {
      // Setting up the groupDevicesId array to have all group devices Id's
      let groupDevicesId = [];

      for (let i = 0; i < this.devices.length; i++) {
        groupDevicesId.push(this.devices[i].id);
      }
      try {
        let response = await BlueprintService.getSensorPosition(
          blueprintId,
          groupDevicesId
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },

    drawSensor(xCoord, yCoord) {
      let position = [];
      let xPosition = parseFloat(
        ((xCoord / this.canvas.width) * 100).toFixed(3)
      );
      let yPosition = parseFloat(
        ((yCoord / this.canvas.height) * 100).toFixed(3)
      );
      position.push(xPosition + xPosition * 0.05);
      position.push(yPosition + yPosition * 0.05);

      // Arc (circles)
      this.ctx.beginPath();
      this.ctx.arc(
        xCoord + xCoord * 0.05,
        yCoord + yCoord * 0.05,
        10,
        0,
        Math.PI * 2
      );
      this.ctx.fillStyle = this.selectedColor;
      this.ctx.fill();
      this.ctx.stroke();

      return position;
    },

    async drawSensors(xCoord, yCoord) {
      this.ctx.beginPath();
      this.ctx.arc(xCoord, yCoord, 10, 0, Math.PI * 2);
      this.ctx.fillStyle = "purple";
      this.ctx.fill();
      this.ctx.stroke();
    },

    // Highlights a specific group of sensors if they are present on the blueprint being displayed
    // The highlighting descriminates between an online (blue) or offline (grey) device
    // Takes in an array of objects with id and status (devices), and a refBlueprint
    // Also can take a boolean (enfatize) and a deviceId to further enfatize a specific device contained on the group
    async highlightDevicesById(devices, refBlueprint, enfatize, deviceId) {
      // If the devices array is empty then we just refresh the display
      // Refreshing the display means drawing the sensors without considering their status or the highlighting
      if (devices.length === 0) {
        return this.refreshDisplay();
      }

      refBlueprint = refBlueprint || 1;
      try {
        this.sensorObj = await this.getSensorPositionObj(refBlueprint);
      } catch (err) {
        console.log("CanvaObj highlightDevicesById", err);
      }
      let self = this;

      this.sensorObj.data.forEach(function (value) {
        value.xCoordinate = parseFloat(
          ((value.xCoordinate / 100) * self.canvas.width).toFixed(3)
        );
        value.yCoordinate = parseFloat(
          ((value.yCoordinate / 100) * self.canvas.height).toFixed(3)
        );
      });
      await this.refreshDisplay();
      self.sensorObj.data.forEach(function (value) {
        var dev = devices.find(dev => dev.id == value.deviceId);
        if(dev) {
          if (dev.status) {
            self.drawGlow(value.xCoordinate, value.yCoordinate);
          } else {
            self.drawGlow(
              value.xCoordinate,
              value.yCoordinate,
              "#9E9E9E"
            );
          }

          if (value.deviceId === deviceId && enfatize) {
            self.drawHalo(value.xCoordinate, value.yCoordinate);
          }
        }
      });
    },

    // Method that paints the body of the sensor representation on the blueprint
    drawGlow(xCoord, yCoord, color) {
      if (color === undefined) {
        color = "#0277BD";
      }
      this.ctx.fillStyle = color;
      this.ctx.beginPath();
      this.ctx.arc(xCoord, yCoord, 10, 0, Math.PI * 2, true);
      this.ctx.closePath();
      this.ctx.fill();
    },

    // Method that paints an halo around the body of the sensor representation on the blueprint
    drawHalo(xCoord, yCoord) {
      var g = this.ctx.createLinearGradient(
        xCoord - 40,
        xCoord + 40,
        yCoord - 40,
        yCoord + 30
      );
      g.addColorStop(0, "rgba(255,0,0,1)");
      g.addColorStop(0.1, "rgba(255,0,0,0.5)");
      g.addColorStop(0.1, "rgba(255,165,0,0.4)");
      g.addColorStop(0.1, "rgba(205,255,0,0.5)");
      g.addColorStop(0.1, "rgba(230,145,0,0.5)");
      g.addColorStop(0.2, "rgba(0,127,255,0.5)");
      g.addColorStop(0.1, "rgba(100,200,205,0.9)");
      g.addColorStop(1, "rgba(0,255,255,0.5)");
      this.ctx.fillStyle = g;
      this.ctx.beginPath();
      this.ctx.arc(xCoord, yCoord, 15, 0, Math.PI * 2, true);
      this.ctx.closePath();
      this.ctx.fill();
    },

    drawGrid() {
      this.gridCounter++;

      if (this.gridCounter % 2) {
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "#424242";

        for (let i = 50; i < this.canvas.width; i = i + 50) {
          this.ctx.beginPath();
          this.ctx.moveTo(i, 0);
          this.ctx.lineTo(i, this.canvas.height);
          this.ctx.stroke();
          this.ctx.strokeText(i, i + 1, 10);
        }

        this.ctx.strokeStyle = "#DD2C00";
        for (let j = 50; j < this.canvas.height; j = j + 50) {
          this.ctx.beginPath();
          this.ctx.moveTo(0, j);
          this.ctx.lineTo(this.canvas.width, j);
          this.ctx.stroke();
          this.ctx.strokeText(j, 1, j - 2);
        }
      } else {
        this.refreshDisplay();
        this.gridCounter = 0;
      }
    },
  },
};
</script>

<style>
/* .canvaObj {
  border: 1px solid burlywood;
} */
</style>
